<template>
  <div class="bind-page">
    <div class="bind-header">
      <p>欢迎绑定用水账户</p>
      <span>您还未绑定任何用水账户，请先绑定！</span>
    </div>
    <div class="bind-content" id="bind">
      <van-form>
        <van-cell-group>
          <!-- <van-field
                        label="缴费单位"
                        placeholder="请选择"
                        right-icon="arrow"
                        label-width="70px"
                        v-model="dataInfo.paymentUnitName"
                        @click="showSelectContent"
                        readonly
                    /> -->
          <!-- 户号后面的文号图标：label-class="title_quester"  -->
          <van-field
            label="户号"
            placeholder="请填写"
            label-width="74px"
            type="number"
            clearable
            v-model="dataInfo.userNo"
            @input="getCheckIsDisabled"
          />
          <!-- <van-cell style="padding: 0px">
                        <van-row>
                            <van-col span="12">
                                <van-field
                                    readonly
                                    label="账单验证"
                                    placeholder="月份"
                                    label-width="70px"
                                    v-model="dataInfo.month"
                                    :right-icon="invertedTriangle"
                                    @click="showPopup"
                                    @input="getCheckIsDisabled"
                                />
                            </van-col>
                            <van-col span="12">
                                <van-field
                                    label="抄见数"
                                    placeholder="请填写"
                                    label-width="65px"
                                    type="digit"
                                    maxlength="10"
                                    v-model="dataInfo.readNum"
                                    @input="getCheckIsDisabled"
                                />
                            </van-col>
                        </van-row>
                    </van-cell> -->
          <!-- 标签 -->
          <van-cell>
            <label-tag
              :value="dataInfo.signName"
              labelWidth="74px"
              @getTagValue="getInputValueInfo"
            />
          </van-cell>
        </van-cell-group>

        <!-- <span class="bind-userinfo" @click="goToUserInfoBind">通过手机/证件绑定</span> -->
        <div class="submit-button">
          <!-- :disabled="isDisabledSubmit"-->
          <van-button block type="info" @click="onSubmit" :disabled="isDisabledSubmit">
            立即绑定
          </van-button>
          <!-- <p>最多可绑定<em>5个</em>用水账户</p> -->
        </div>
      </van-form>
      <!-- 隐藏户号扫描 -->
      <!-- @change="handleClick" -->
      <input class="camera" ref="camera" type="file" accept="image/*" capture="camera" multiple />
    </div>

    <!-- 弹出框 -->
    <van-popup
      v-model="show"
      round
      closeable
      close-icon-position="top-left"
      position="bottom"
      :style="{ height: heightFlag ? '60%' : '68%' }"
      get-container="#bind"
    >
      <div class="title">
        <p>请选择账单月份</p>
        <span>需为最近一年账单</span>
      </div>
      <bottom-selection :dataValArr="monthData" @getSelectValue="getSelectValueInfo" />
    </van-popup>
    <!-- 弹出框 - 选择缴费单位 -->
    <van-popup v-model="showSelectPopup" :style="{ height: '100%', width: '100%' }">
      <div class="select-content">
        <van-cell-group>
          <van-radio-group v-model="radio">
            <van-radio
              v-for="(item, index) in paymentUnitList"
              :key="index"
              :name="item.paymentNo"
              @click="changeRadio(item)"
            >
              <van-cell :title="item.paymentName">
                <template #icon="props">
                  <van-icon name="success" v-if="props.checked" />
                </template>
              </van-cell>
            </van-radio>
          </van-radio-group>
        </van-cell-group>
      </div>
    </van-popup>
    <!-- 弹出框 -->
    <van-popup v-model="userNoExampleShow" :style="{ height: '100%', width: '100%' }">
      <div class="dialogContent" @click="userNoExampleShow = false">
        <!-- <img :src="pic_userNoExample" alt="示例"> -->
      </div>
    </van-popup>
  </div>
</template>

<script>
import scan from '../../assets/icon/scan@2x.png';
import invertedTriangle from '../../assets/icon/invertedTriangle@2x.png';
import pic_userNoExample from '../../assets/background/pic_userNoExample.png';
import LabelTag from '@/components/labelTag';
import BottomSelection from './module/bottomSelection';
import { bindUserByMeterCode, selectPaymentList } from '../../api/home';

import axios from 'axios';
import { Toast } from 'vant';
import store from '@/store';
import { mapState } from 'vuex';

export default {
  name: 'Bind',
  components: {
    LabelTag,
    BottomSelection
  },
  data() {
    return {
      scan,
      invertedTriangle,
      pic_userNoExample,
      show: false,
      dataInfo: {
        paymentUnit: '',
        paymentUnitName: '',
        readNum: '',
        billMonth: '',
        month: '',
        signName: '',
        userNo: ''
      },
      tagListData: ['我家', '父母家', '朋友家'],
      monthData: [],
      currentMounth: '',
      heightFlag: true,
      submitDisabled: true,
      showSelectPopup: false,
      radio: '1',
      paymentUnitList: [],
      isDisabledSubmit: true, // 立即绑定按钮---是否禁用
      userNoExampleShow: false
    };
  },
  computed: {
    ...mapState('user', {
      homeInfo: (state) => state.homeInfo,
      pathUrl: (state) => state.pathUrl
    })
  },
  mounted() {
    this.getMounth();
    this.selectPaymentList();
  },
  methods: {
    // 缴费单位
    async selectPaymentList() {
      const { status, resultData } = await selectPaymentList();
      if (status === 'complete') {
        this.paymentUnitList = resultData;
      }
    },
    // 缴费单位-弹框出现
    showSelectContent() {
      this.showSelectPopup = true;
    },
    // 选择缴费单位
    changeRadio(data) {
      this.dataInfo.paymentUnitName = data.paymentName;
      this.dataInfo.paymentUnit = data.paymentNo;
      this.showSelectPopup = false;
      this.getCheckIsDisabled();
    },
    showPopup() {
      this.show = true;
    },
    // 选择年-月
    getSelectValueInfo(year, month) {
      this.dataInfo.month = month;
      let monthString = month.substring(0, month.lastIndexOf('月'));
      this.dataInfo.billMonth = `${year}-${monthString}`;
      this.show = false;
      this.getCheckIsDisabled();
    },
    // 通过身份证/手机号绑定页面
    goToUserInfoBind() {
      this.$router.push({
        path: '/Bind/householderInfo'
      });
    },
    // 标签
    getInputValueInfo(msg) {
      this.dataInfo.signName = msg;
      this.getCheckIsDisabled();
    },
    // 提交
    async onSubmit() {
      if (Object.values(this.dataInfo).length > 0) {
        const arg = this.dataInfo;
        const { status, resultData, errorMessage } = await bindUserByMeterCode(arg);
        if (status === 'complete') {
          // 从首页进来---提交成功后 跳转到首页
          await store.dispatch('user/getHomeInfo', '');
          if (this.homeInfo.isBind) {
            if (
              this.pathUrl &&
              this.pathUrl.url &&
              this.pathUrl.url.length > 0 &&
              this.pathUrl.url !== '/Bind'
            ) {
              this.$router.push({ path: this.pathUrl.url });
            } else {
              this.$router.push({ path: '/', name: 'Home' });
            }
          } else {
            this.$router.push({ path: '/', name: 'Home' });
          }
        } else {
          Toast.fail(errorMessage);
        }
      }
    },
    // 提交按钮是否置灰
    getCheckIsDisabled(event) {
      const { userNo, signName } = this.dataInfo;
      // 抄见数-不能输入-
      // if (readNum.indexOf('-') >= 0) {
      //   this.dataInfo.readNum = readNum.split('-')[1];
      // }
      if (userNo.length > 0 && signName.length > 0) {
        this.isDisabledSubmit = false;
      } else {
        this.isDisabledSubmit = true;
      }
    },
    // 选择账单月份
    getMounth() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      let objMonthOne = {};
      let objMonthTwo = {};

      let monthList = [];
      for (let i = 1; i <= month; i++) {
        monthList.push({
          name: i + '月'
        });
      }
      objMonthOne.year = year;
      objMonthOne.yearMounth = monthList.reverse();
      this.monthData.push(objMonthOne);
      // 如果当前月份小于12 则加前面一年的月份
      if (month < 12) {
        this.heightFlag = false;
        let preMonthList = [];
        for (let i = month + 1; i <= 12; i++) {
          preMonthList.push({
            name: i + '月'
          });
        }
        const preYear = year - 1;
        objMonthTwo.year = preYear;
        objMonthTwo.yearMounth = preMonthList;
        this.monthData.push(objMonthTwo);
      }
    },
    seeUserNoExample() {
      this.userNoExampleShow = true;
    }
    // getScan() {
    //     this.$refs.camera.click();
    // },

    // 扫描
    // handleClick() {
    //     let file = this.$refs.camera.files[0];
    //     if (!file) return;
    //     this.loading = true;

    //     console.log('上传文件前---', file)
    //     var forms = new FormData();
    //     forms.append('file', file)
    //     let config = {
    //         headers: {'Content-Type': 'multipart/form-data'}
    //     };
    //     axios.post('https://mp.weixin.qq.com/wxamusic/ocr/apidebug_imagequery?action=ocr_comm', forms, config)
    //         .then (res => {
    //             console.log(res);
    //             this.loading = false;
    //             if (res.data.ocrcomm_res.items.length !== 0) {
    //                 console.log('显示的数据--', res.data)
    //                 Toast({
    //                     message: '顶部展示',
    //                     position: 'top',
    //                 });
    //             } else {
    //                 console.log('没数据--')
    //                 this.readNum = ''
    //             }
    //         }).catch(err => {
    //             console.log(err)
    //         })
    // }
  }
};
</script>

<style lang="less" scoped>
.bind-page {
  overflow-x: hidden;
  background-color: #ffffff;
  .bind-header {
    margin-top: 33px;
    padding-left: 16px;
    text-align: left;
    p {
      font-family: PingFangSC-Medium;
      font-size: 24px;
      color: #1c2734;
      line-height: 26px;
      margin: 0px;
    }
    span {
      display: block;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #999999;
      line-height: 26px;
      line-height: 26px;
      margin-bottom: 36px;
      padding-top: 2px;
    }
  }
  .bind-content {
    display: inline-block;
    width: 100%;
    /deep/.van-cell__title {
      span {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #333333;
      }
    }
    /deep/.van-field__control {
      display: inline-block;
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #5e6268;
    }
    /deep/.van-field__right-icon {
      font-size: 16px;
    }
    /deep/.title_quester::after {
      display: inline-block;
      width: 18px;
      height: 18px;
      content: '';
      vertical-align: middle;
      background: url('../../assets/icon/question@2x.png') no-repeat 3px -1px;
      background-size: cover;
    }
    /deep/.van-field__label {
      margin-right: 0px;
    }
    .bind-userinfo {
      text-align: left;
      margin: 0px;
      padding-left: 16px;
      // padding-top: 14px;
      // padding-bottom: 14px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #4466ad;
      padding-top: 20px;
      padding-bottom: 10px;
      display: inline-block;
    }
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #cccccc;
      font-size: 16px;
    }
    .submit-button {
      border-radius: 8px;
      margin: 62px 16px 0px;
      margin-bottom: 100px;
      p {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #999999;
        text-align: center;
        line-height: 17px;
        margin-top: 12px;
        em {
          font-style: normal;
        }
      }
      .van-button__text {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
      }
      .van-button {
        border-radius: 8px;
      }
    }
  }
  .camera {
    // visibility: hidden;
    display: none;
  }
  .title {
    border-bottom: 1px solid #eeeeee;
    padding-top: 12px;
    padding-bottom: 12px;
    p {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #333333;
      text-align: center;
      line-height: 26px;
      margin: 0px;
    }
    span {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #999999;
      text-align: center;
      line-height: 26px;
      display: block;
    }
  }
  .editLabel {
    .title {
      border-bottom: 1px solid #eeeeee;
      padding-top: 12px;
      padding-bottom: 12px;
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #333333;
      text-align: center;
      line-height: 22px;
    }
    .inputLabel {
      margin: 0 auto;
      width: 90%;
      margin: 12px;
      border: 1px solid red;
      background: #f9f8f8;
      border-radius: 4px;
      border-radius: 4px;
      margin-left: 20px;
    }
    .button {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #0a79ec;
      text-align: center;
    }
  }
  // 选择弹出框
  .select-content {
    /deep/.van-cell__title {
      text-align: left;
    }
    /deep/.van-cell__value {
      text-align: left;
    }
    /deep/.van-radio__icon .van-icon {
      border: 0px solid transparent;
    }
    /deep/.van-radio__icon--checked .van-icon {
      background-color: transparent;
      border-color: transparent;
      color: #1989fa;
    }
    /deep/.van-radio {
      position: relative;
      margin: 0px 16px;
      border-bottom: 1px solid #eeeeee;
    }
    /deep/.van-radio__icon {
      position: absolute;
      right: 0px;
    }
    .van-cell {
      padding-left: 0px;
    }
  }
  // 户号示例
  .dialogContent {
    width: 100%;
    height: 100%;
    background: url('../../assets/background/pic_userNoExample.png') no-repeat left top;
    background-repeat: round;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
